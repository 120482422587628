<!--
 * @Author: your name
 * @Date: 2021-03-04 09:14:59
 * @LastEditTime: 2023-07-06 14:15:40
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \lsz-user-mp\src\views\appointment\detail.vue
-->
<template>
  <div class="page-wrap">
    <div class="appointmentDetail">
      <!-- type=1 预约 type=2 挂号 -->
      <div v-if="type=='1'" class="appointment-head" :class="newappointmentStatus ? '' : 'fail'">
        <template v-if="newappointmentStatus">
          <div class="subject">
            <img class="img" :src="require('../../assets/img/sucess_icon.png')">
            预约成功待付款
          </div>
          <div>
            预约成功，请在就诊日当天“我的”-“我的预约”中支付
            <br>挂号费用，取消预约请在就诊前一日24点前操作。
          </div>
        </template>
        <template v-else>
          <div class="subject">
            <img class="img" :src="require('../../assets/img/fail_icon.png')">
            已取消预约
          </div>
          <div>取消原因：{{ qxyy }}</div>
        </template>
      </div>
      <div v-else class="appointment-head">
        <div class="subject">
          <img class="img" :src="require('../../assets/img/sucess_icon.png')">
          挂号序号：{{ detail.yyxh }}
        </div>
        <div>{{ detail.ddztmc }}</div>
      </div>
      <div class="appointmentDetail-body">
        <div class="info">
          <div v-if="type=='1'" class="info-number">
            预约序号：{{ detail.yyxh }}
          </div>
          <div class="info-item">
            <span class="info-item-title">就诊人</span>
            <div class="con">
              <span class="name">{{ detail.jzrxm }}</span>
              {{ detail.jzrkh }}
            </div>
            <div v-if="detail.jzklx === '1'" class="payment-tips">
              <span>当前选择支付卡支持医保支付</span>
            </div>
          </div>
          <div class="info-item">
            <span class="info-item-title">就诊类型</span>
            <div class="con">
              {{ detail.hylxmc }}
            </div>
          </div>
          <div class="info-item">
            <span class="info-item-title">就诊科室</span>
            <div class="con">
              {{ detail.ksmc }}
            </div>
          </div>
          <div class="info-item">
            <span class="info-item-title">就诊日期</span>
            <div class="con">
              {{ detail.hyrq }} {{ detail.sxwbzmc }}
            </div>
          </div>
          <div class="info-item info-item__tip">
            <span class="info-item-title">挂号费用</span>
            <div class="con">
              <span :class="appointmentStatus ? 'price' : ''">¥ {{ detail.ghf }}</span>
            </div>
          </div>
          <div class="info-item">
            <span class="info-item-title">确诊疾病</span>
            <div class="cons">
              {{ ill }}
            </div>
          </div>
          <div class="info-item info-item-s">
            <div class="label">
              病情主诉
            </div>
            <div class="ill-desc">
              {{ detail.bqzs }}
            </div>
          </div>
          <div class="info-item info-item-s">
            <div class="label">
              上传材料
            </div>
            <van-grid :border="false" :column-num="3">
              <van-grid-item v-for="(image,index) in detail.pictures" :key="index">
                <img class="upImage" :src="image">
              </van-grid-item>
            </van-grid>
          </div>
          <div class="info-item">
            <span class="info-item-title">凭证单号</span>
            <div class="con">
              {{ detail.yydbm }}
            </div>
          </div>
          <div class="info-item">
            <span class="info-item-title">提交日期</span>
            <div class="con">
              {{ detail.tjrq }}
            </div>
          </div>
        </div>
      </div>

      <van-dialog
        v-model="show"
        :title="'请选择取消原因'"
        show-confirm-button
        show-cancel-button
        theme="round-button"
        confirm-button-text="确定"
        cancel-button-text="取消"
        confirm-button-color="#3A8AE5"
        cancel-button-color="#DDDDDD"
        @confirm="clickConfirm"
      >
        <div class="reason-list">
          <div
            v-for="(v,index) in cancelContent"
            :id="v.id"
            :key="v.id"
            class="reason-item"
            :data-name="v.name"
            :data-current="index"
            :class="tabIndex==index ? 'active' : ''"
            @click="cancelChoose"
          >
            {{ v.name }}
          </div>
        </div>
      </van-dialog>
      <van-dialog
        v-model="isCancelVisible"
        :title="'提示'"
        show-confirm-button
        show-cancel-button
        theme="round-button"
        confirm-button-text="确定"
        cancel-button-text="取消"
        confirm-button-color="#3A8AE5"
        cancel-button-color="#DDDDDD"
        @confirm="sure"
        @cancel="closeModel"
      >
        <div class="reason-list">
          {{ cancelMsg }}
        </div>
      </van-dialog>
    </div>
    <div v-if="newappointmentStatus" class="modal-foot">
      <van-button
        class="btn btn-default"
        plain
        round
        @click="cancelsChoose"
      >
        取消预约
      </van-button>
      <van-button
        v-if="payStaus"
        class="btn btn-primary"
        plain
        round
        type="primary"
        :loading="primaryLoading"
        @click="payPage"
      >
        挂号支付
      </van-button>
      <div v-else class="btn btn-primary" @click="goback">
        返回首页
      </div>
    </div>
    <div v-else class="modal-foot">
      <div class="btn btn-primary" @click="goback">
        返回首页
      </div>
    </div>
    <div v-if="typeStaus" class="modal-foot">
      <div v-if="payStaus" class="btn btn-primary" @click="payPage">
        挂号支付
      </div>
      <div v-else class="btn btn-primary" @click="goback">
        返回首页
      </div>
    </div>
  </div>
</template>
<script>
import {appointModel} from '@/api/appoint'
import {addDate} from '../../utils/index'
import {CONSTANT}from '../../utils/constant'
import {SET_HEADER_CONFIG} from '../../store/mutation-types'
export default {
  data(){
    return{
      type: this.$route.query.type,
      detail:{},
      ill:'',
      newappointmentStatus: true,
      isCancelVisible:false,
      typeStaus:false,
      appointmentStatus: true, // 预约状态： false->已取消
      show:false,
      payStaus:false,
      tabIndex: null,
      qxyy:"",
      id:"",
      errorName:'',
      cancelMsg:'',
      cancelContent:[
        {
          name: '临时有事',
          id: 'nsys'
        },
        {
          name: '重复预约',
          id: 'cfyy'
        },
        {
          name:'不想去了',
          id:'bxql'
        },
        {
          name:'约错时间',
          id:'ycsj'
        },
        {
          name:"约错科室",
          id:'ycks'
        },
        {
          name:"约错医生",
          id:"ycys"
        }
      ],
      primaryLoading: false
    }
  },
  mounted(){
    const {id,yydzt,type}=this.$route.query
    this.id=id
    this.getDetail()
    //yydzt 预约单状态 0.申请成功，1、 履约 2、 未履约 3、取消预约 4、 停诊 9、其他
    if(yydzt==='3'){
        this.newappointmentStatus=false
        this.typeStaus = false
        this.appointmentStatus = false
        this.$store.commit(`header/${SET_HEADER_CONFIG}`, { title:'取消预约'})
    }
    if(type==='2'){
        this.appointmentStatus = false
        this.typeStaus = true
    }
  },
  methods:{
    payPage(){
      this.primaryLoading = true
      appointModel.createRegisterOrder({appointId:this.detail.appointId}).then((res)=>{
        if(res.code==CONSTANT.SUCCESS_CODE){
          // 50 挂号 51 处方
          return appointModel.checkstandCreate({lb:50, orderCode: res.data.registerid})
        }
        // this.$router.push({
        //   path:'/registerPay',
        //   query:{id:res.data.id}
        // })
      }).then(res => {
        if(res.code==CONSTANT.SUCCESS_CODE){
          location.href = res.data.webURL
        }
      }).catch(error=>{
        this.$toast(error.msg)
      }).finally(() => {
        this.primaryLoading = false
      })
    },
    goback(){
      this.$router.push({
        path:'/'
      })
    },
    getDetail(){
      appointModel.appointmentDetail({appointId:this.id}).then(res=>{
        this.detail = res.data
         this.qxyy = res.data.qxyy
        if(res.data.diagnosis){
          this.detail.diagnosis.map((v)=>{
            this.ill+=v.zdmc+';'
          })
        }
        if(res.data.hyrq==addDate(new Date(),0 )){
          this.payStaus=true
        }
      })
    },
    clickConfirm(){
      if(this.errorName==''){
        this.$toast('请选择取消原因')
        return
      }
      appointModel.cancel({appointId:this.id,qxyy:this.errorName}).then(res=>{
        if(res.code==CONSTANT.SUCCESS_CODE){
          this.qxyy=res.data.qxyy
        }
      })
      this.isCancelAppointVisible = false
      this.newappointmentStatus = false
      this.appointmentStatus = false
      this.typeStaus = false
    },
    cancelChoose(e){
      let index = e.target.dataset.current || e.currentTarget.dataset.current
      this.errorName=e.target.dataset.name
      this.tabIndex=index
    },
    async cancelsChoose(){
      if(this.detail.hyrq<addDate(new Date(),0 )){
         this.$toast('不能取消预约!')
      }else{
        const {data}=await appointModel.countCancel({appointId:this.id})
        this.isCancelVisible=true
        this.cancelMsg=data.message
      }
    },
    cancelSure(){

    },
    sure(){
    this.isCancelVisible=false
    this.show=true
    },
    closeModel(){
      this.isCancelVisible=false
    }
  }
}
</script>
<style scoped lang='less'>
@import "../../assets/styles/variable.less";
.payment-tips{
  text-align: left;
  width: 686px;
  box-sizing: border-box;
  margin: 0 auto 0;
  border-radius: 12px;
  color: #f56c6c;
  font-size: 22px;
}
div {
  box-sizing: border-box;
}
.page-wrap {
  padding-bottom: 108px;
  background: @color-F2F2F2;
}
.appointmentDetail {
  padding: 0 32px;
  overflow: scroll;
  .appointment-head {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 272px;
    padding: 40px;
    line-height: 33px;
    font-size: 26px;
    color: #fff;
    text-align: center;
    background: #1c9cf6;

    &.fail {
      background: #b5b5b5;
    }

    .subject {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 33px;
      font-size: 32px;
      line-height: 45px;

      .img {
        width: 44px;
        height: 44px;
        margin-right: 16px;
      }
    }
  }
  .appointmentDetail-body {
    position: relative;
    z-index: 2;
    padding: 223px 0px 0px;

    .info {
      padding: 40px 32px;
      border-radius: 20px;
      background: #fff;

      .info-number {
        margin-bottom: 40px;
        font-weight: bold;
        font-size: 28px;
        color: #3d3d3d;
        text-align: center;
      }

      .info-item {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 20px 0;
        line-height: 40px;
        font-size: 28px;
        color: #3d3d3d;
        &.info-item__tip{
          padding-bottom: 0;
        }

        &.info-item-s {
          display: block;

          .label {
            margin-bottom: 24px;
            font-weight: bold;
          }
          .upImage {
            width: 120px;
            height: 120px;
          }
        }

        .info-item-title {
          font-weight: bold;
        }

        .con {
          flex: 1;
          color: #666;
          text-align: right;

          .name {
            margin-right: 24px;
          }

          .price {
            color: #dc3030;
          }
        }

        .cons {
          flex: 1;
          color: #666;
          text-align: right;
          width: 300px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .ill-desc {
          line-height: 33px;
          font-size: 26px;
          color: #999;
        }
      }

      .info-item-upload {
        align-items: flex-start;

        .con {
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }

        .pic,
        .more {
          width: 120px;
          height: 120px;
          border-radius: 8px;
        }

        .pic {
          margin-right: 55px;
          background: #ccc;
        }

        .more {
          display: flex;
          align-items: center;
          justify-content: center;
          background: #eee;

          .img {
            width: 34px;
            height: 6px;
          }
        }
      }
    }
  }

  .reason-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0px 32px;
    margin: 40px 0;
    .reason-item {
      min-width: 160px;
      height: 52px;
      margin: 12px 22px;
      line-height: 52px;
      font-size: 28px;
      color: #666;
      text-align: center;
      background: #f2f2f2;

      &.active {
        color: #fff;
        background: #1c9cf6;
      }
    }
  }
  .van-dialog {
    width: 686px;
  }
  /deep/.van-dialog--round-button .van-dialog__confirm {
  }
  /deep/.van-dialog--round-button .van-dialog__cancel {
  }
  /deep/.van-goods-action-button--first {
    border-radius: 12px;
  }
  /deep/.van-goods-action-button--last {
    border-radius: 12px;
  }
}
.modal-foot {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 98px;
  line-height: 98px;
  background: #f2f2f2;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 999;
  .btn {
    width: 322px;
    height: 68px;
    line-height: 68px;
    font-size: 30px;
    text-align: center;
    border-radius: 12px;
  }
  .btn-default {
    color: #666;
    background: #ddd;
  }
  .btn-primary {
    color: #fff;
    background: #1c9cf6;
    border-color: #1c9cf6;
  }
}
</style>
